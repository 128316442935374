import type { HTMLAttributes } from 'react';

import { cn } from '../../utils';

type SkeletonProps = Readonly<HTMLAttributes<HTMLDivElement>>;

function Skeleton({ className, ...props }: SkeletonProps) {
  return <div className={cn('animate-pulse rounded-md bg-neutral-5/10', className)} {...props} />;
}

export { Skeleton };
