import type { RouteObject } from 'react-router';

import AdminAuthenticatedLayout from '@/layouts/authenticated/authenticated.layout';

import { DashboardRoutes } from './dashboard/routes';
import NotFoundPage from './not-found.page';
import { OrganizationRoutes } from './organization/routes';
import { UserRoutes } from './user/routes';

export const routes: RouteObject[] = [
  {
    path: '/',
    ErrorBoundary: NotFoundPage,
    children: [
      {
        Component: AdminAuthenticatedLayout,
        children: [...DashboardRoutes, ...UserRoutes, ...OrganizationRoutes],
      },
      {
        path: '/404',
        Component: NotFoundPage,
      },
    ],
  },
];
