import type { PropsWithClassName } from '@bluemarker/core';

import { cn } from '../../utils';
import { Separator } from '../base';
import BluemarkerFullLogo from '../bluemarker-full-logo';
import { useSidebarContext } from './context';

export type SidebarLogoProps = PropsWithClassName<{
  logoUrl?: string;
  bluemarkerPlatform?: string;
}>;

const SidebarLogo = ({ logoUrl, bluemarkerPlatform, className }: SidebarLogoProps) => {
  const { isCollapsed } = useSidebarContext();

  const renderBluemarkerFullLogo = () => {
    return bluemarkerPlatform ? (
      <div>
        <BluemarkerFullLogo />
        <p className="text-xs">{bluemarkerPlatform}</p>
      </div>
    ) : (
      <BluemarkerFullLogo />
    );
  };

  return (
    <div
      className={cn(
        'transition-all ease-in-out overflow-hidden duration-300',
        isCollapsed ? 'animate-out fade-out-0 max-h-0' : 'animate animate-in fade-in-0 max-h-[calc(6rem+1px)]',
        className
      )}
    >
      <div className="h-24 flex items-center justify-center">
        {logoUrl ? (
          <img alt="organization-logo" className="max-h-full max-w-full" src={logoUrl} />
        ) : (
          renderBluemarkerFullLogo()
        )}
      </div>
      <Separator />
    </div>
  );
};

export default SidebarLogo;
