'use client';

import { type PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { type SidebarContext, SidebarContextProvider } from './context';

export type SidebarRootProps = PropsWithChildren<{
  className?: string;
}>;

const SIDEBAR_KEY = 'sidebar';

const Root = ({ children }: SidebarRootProps) => {
  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem(SIDEBAR_KEY) ? localStorage.getItem(SIDEBAR_KEY) === 'true' : true
  );

  useEffect(() => {
    localStorage.setItem(SIDEBAR_KEY, isCollapsed.toString());
  }, [isCollapsed]);

  const value = useMemo<SidebarContext>(
    () => ({
      isCollapsed,
      setIsCollapsed,
    }),
    [isCollapsed]
  );

  return <SidebarContextProvider value={value}>{children}</SidebarContextProvider>;
};

export default Root;
