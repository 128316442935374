'use client';

import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { type PropsWithChildren, useEffect, useState } from 'react';
import { Link } from 'react-router';

import { ChevronUpIcon, type Icon } from '../../icons';
import { cn } from '../../utils';
import { Button, Popover, PopoverContent, PopoverTrigger, Tooltip, TooltipContent, TooltipTrigger } from '../base';
import { useSidebarContext } from './context';

export type SidebarNavProps = PropsWithChildren<{
  className?: string;
  active: boolean;
  Icon: Icon;
  label: string;
  handler?: () => void;
  href?: string;
}>;

const SidebarNav = ({ className, children, active, Icon, label, href, handler }: SidebarNavProps) => {
  const { isCollapsed } = useSidebarContext();
  const [open, setOpen] = useState(false);
  const [activePopover, setActivePopover] = useState(false);

  const handleOpenChange = (popoverOpen: boolean) => {
    if (href) return;

    if (handler) return handler();

    if (!isCollapsed) {
      setOpen((prev) => !prev);
    } else {
      setOpen(popoverOpen);
    }
  };

  useEffect(() => {
    if (isCollapsed) {
      setOpen(false);
      setActivePopover(true);
    } else {
      setActivePopover(false);
      setOpen(active);
    }
  }, [isCollapsed, active]);

  const button = (
    <Button
      className={cn(
        'transition-all duration-300 flex justify-between w-full gap-2',
        isCollapsed && 'justify-center h-11 w-11 mx-auto',
        className
      )}
      variant={active ? 'default' : 'ghost'}
      size={isCollapsed ? 'icon' : 'default'}
    >
      <span className="flex items-center truncate">
        <Icon
          className={cn('h-4 min-w-4 w-4 transition duration-300 ease-in-out', isCollapsed && 'h-5 min-w-5 w-5')}
          style={{
            transitionProperty: 'height, width',
          }}
        />
        <span
          className={cn(
            'truncate transition-[max-width] ease-in-out duration-300',
            isCollapsed ? 'ml-0 max-w-0 animate-out fade-out-0' : 'ml-3 animate-in fade-in-0'
          )}
        >
          {label}
        </span>
      </span>
      {!isCollapsed && children && open && (
        <ChevronUpIcon className="h-4 min-w-4 w-4 animate-in rotate-180 fade-in-0 duration-300" />
      )}
    </Button>
  );

  return (
    <Popover open={activePopover && open} onOpenChange={handleOpenChange}>
      <AccordionPrimitive.Root type="single" collapsible value={!isCollapsed && open ? label : ''}>
        <AccordionPrimitive.Item value={label}>
          <Tooltip open={!isCollapsed ? false : undefined}>
            <TooltipTrigger asChild>
              <PopoverTrigger asChild>
                <AccordionPrimitive.Trigger asChild>
                  {href ? (
                    <Link className="w-full" to={href}>
                      {button}
                    </Link>
                  ) : (
                    button
                  )}
                </AccordionPrimitive.Trigger>
              </PopoverTrigger>
            </TooltipTrigger>
            <TooltipContent side="right">{label}</TooltipContent>
          </Tooltip>
          <AccordionPrimitive.Content
            className={cn(
              'overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
              'border-l border-neutral-2 rounded-none ml-[1.4375rem] w-[calc(100%-1.4375rem)] p-1',
              isCollapsed && 'opacity-0',
              !children && 'p-0'
            )}
            asChild
          >
            <ul>{children}</ul>
          </AccordionPrimitive.Content>
        </AccordionPrimitive.Item>
      </AccordionPrimitive.Root>

      {isCollapsed && (
        <PopoverContent className="w-56 px-3 py-2" side="right" align="start">
          <ul>{children}</ul>
        </PopoverContent>
      )}
    </Popover>
  );
};

export default SidebarNav;
