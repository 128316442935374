import { lazy } from 'react';
import type { RouteObject } from 'react-router';

const NewOrganizationPage = lazy(() => import('./new/page'));
const OrganizationDetailPage = lazy(() => import('./[subdomain]/page'));
const OrganizationsPage = lazy(() => import('./page'));

export const OrganizationRoutes: RouteObject[] = [
  {
    path: '/organization',
    Component: OrganizationsPage,
  },
  {
    path: '/organization/new',
    Component: NewOrganizationPage,
  },
  {
    path: '/organization/:subdomain',
    Component: OrganizationDetailPage,
  },
  // define more routes here ...
];
