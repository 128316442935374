import type { PropsWithEssentials } from '@bluemarker/core';

import { cn } from '../../utils';
import { useSidebarContext } from './context';

export type SidebarContentProps = PropsWithEssentials;

const Content = ({ className, children }: SidebarContentProps) => {
  const { isCollapsed } = useSidebarContext();

  return (
    <div>
      <nav
        className={cn(
          'relative w-[calc(16rem+1px)] min-w-[calc(16rem+1px)] border-r border-neutral-2 h-screen transition-all duration-300 ease-in-out pb-6 flex flex-col bg-neutral-0',
          isCollapsed && 'w-[calc(3.75rem+1px)] min-w-[calc(3.75rem+1px)] py-6',
          className
        )}
      >
        {children}
      </nav>
    </div>
  );
};

export default Content;
