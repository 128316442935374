import { LoadingScreen } from '@bluemarker/ui';
import { type PropsWithChildren, useEffect } from 'react';

import useQueryUserProfile from '@/apis/authentication/use-query-user-profile';

const AuthenticatedGuard = ({ children }: PropsWithChildren) => {
  const { isLoading, isError, data } = useQueryUserProfile();

  const shouldRedirectAccountPlatform = isError || (data && !data.isAdmin);

  useEffect(() => {
    if (shouldRedirectAccountPlatform) {
      window.location.href = import.meta.env.VITE_ACCOUNT_PLATFORM_URL + '/' + 'admin';
    }
  }, [shouldRedirectAccountPlatform]);

  if (isLoading || shouldRedirectAccountPlatform) {
    return <LoadingScreen />;
  }

  return children;
};

export default AuthenticatedGuard;
