import { SidebarRoot } from '@bluemarker/ui';
import { Outlet } from 'react-router';

import AdminSidebar from './sidebar';

const AdminAuthenticatedLayout = () => {
  return (
    <SidebarRoot>
      <div className="flex">
        <AdminSidebar />
        <Outlet />
      </div>
    </SidebarRoot>
  );
};

export default AdminAuthenticatedLayout;
