import { type I18nTranslation, useExtendI18nTranslation } from '@bluemarker/core';
import {
  cn,
  Separator,
  SidebarCollapseButton,
  SidebarContent,
  SidebarLogo,
  SidebarNav,
  useSidebarContext,
} from '@bluemarker/ui';
import { Building2Icon, LogOutIcon, UsersIcon } from '@bluemarker/ui/icons';
import { Link, useLocation } from 'react-router';

import useSignOut from '@/apis/authentication/use-sign-out';

import SidebarUserDropdown from './sidebar-user-dropdown';

const TRANSLATION: I18nTranslation = {
  en: {
    dashboard: 'Dashboard',
    organizations: 'Organizations',
    users: 'Users',
    signOut: 'Sign out',
    help: 'Help',
  },
  vi: {
    dashboard: 'Trang chủ',
    organizations: 'Doanh nghiệp',
    users: 'Người dùng',
    help: 'Hỗ trợ',
    signOut: 'Đăng xuất',
  },
};

const AdminSidebar = () => {
  const { pathname } = useLocation();
  const { isCollapsed } = useSidebarContext();

  const { t } = useExtendI18nTranslation(TRANSLATION);

  const { mutate: signOut } = useSignOut();

  return (
    <SidebarContent>
      <SidebarCollapseButton />

      <div className={cn(isCollapsed ? 'px-2' : 'px-6')}>
        <Link to="/">
          <SidebarLogo bluemarkerPlatform="Admin Platform" />
        </Link>
        <SidebarUserDropdown />
        <Separator />
      </div>

      <div className="flex flex-col flex-1 overflow-hidden my-3">
        <div className={cn('flex-1 overflow-auto space-y-2 py-1', isCollapsed ? 'px-2' : 'px-6')}>
          <SidebarNav label={t('users')} Icon={UsersIcon} active={pathname.startsWith('/user')} href="/user" />

          <SidebarNav
            label={t('organizations')}
            Icon={Building2Icon}
            active={pathname.startsWith('/organization')}
            href="/organization"
          />
        </div>
      </div>

      <div className={cn(isCollapsed ? 'px-2' : 'px-6')}>
        <SidebarNav
          className="text-danger-4 hover:text-danger-4 hover:bg-danger-4/10"
          label={t('signOut')}
          Icon={LogOutIcon}
          active={false}
          handler={signOut}
        />
      </div>
    </SidebarContent>
  );
};

export default AdminSidebar;
